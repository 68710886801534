import { supabase } from '../supabaseClient.js';

// Left panel for nations
const nationButton = document.getElementById('nation-button');
const leftSlidingPanel = document.getElementById('left-sliding-panel');
const btnChangeNationName = document.getElementById('btn-change-nation-name');

let isEditingNationName = false;
let currentNationId = null; // Add this line

// Touch variables
let leftPanelStartX = 0;
let leftPanelCurrentX = 0;
let leftPanelTouching = false;

// Add touch event listeners
leftSlidingPanel.addEventListener('touchstart', onLeftPanelTouchStart);
leftSlidingPanel.addEventListener('touchmove', onLeftPanelTouchMove);
leftSlidingPanel.addEventListener('touchend', onLeftPanelTouchEnd);

function onLeftPanelTouchStart(e) {
  leftPanelStartX = e.touches[0].clientX;
  leftPanelTouching = true;
}

function onLeftPanelTouchMove(e) {
  if (!leftPanelTouching) return;
  leftPanelCurrentX = e.touches[0].clientX;
  const translateX = Math.min(0, leftPanelCurrentX - leftPanelStartX);
  leftSlidingPanel.style.transform = `translateX(${translateX}px)`;
}

function onLeftPanelTouchEnd(e) {
  leftPanelTouching = false;
  const swipeDistance = leftPanelCurrentX - leftPanelStartX;
  if (swipeDistance < -50) {
    // User swiped left enough to close the panel
    closeLeftPanel();
  } else {
    // Reset the panel position
    leftSlidingPanel.style.transform = '';
  }
}

nationButton.addEventListener('click', function () {
  leftSlidingPanel.classList.toggle('open');
  leftSlidingPanel.style.transform = ''; // Reset transform when opening
});

const leftPanelCloseButton = document.getElementById('left-panel-close-button');

leftPanelCloseButton.addEventListener('click', closeLeftPanel);

export function closeLeftPanel() {
  leftSlidingPanel.classList.remove('open');
  leftSlidingPanel.style.transform = ''; // Reset transform when closing
}

export function leftPanelSetInfo(obj) {
  document.getElementById('lp-nation-name').innerHTML = obj.nation_name;
  document.getElementById('lp-nation-owner').innerHTML = obj.nation_user;
  document.getElementById('lp-nation-manpower').innerHTML = obj.nation_manpower;
  document.getElementById('lp-nation-money').innerHTML = obj.nation_money;

  currentNationId = obj.nation_id; 

  const nation_id = obj.nation_id;

  if (window.currentUser.id == obj.nation_user_id) {
	  btnChangeNationName.style.display = 'flex';
	}
  else {
    btnChangeNationName.style.display = 'none';
  }

  // Compute the number of loyal settlements in the nation
  let loyalSettlementCount = 0;
  for (let settlementId in window.map.settlements) {
    const settlement = window.map.settlements[settlementId];
    if (settlement.nation_id === nation_id && settlement.loyal) {
      loyalSettlementCount++;
    }
  }

  // Compute the number of provinces the nation owns
  let provinceCount = 0;
  for (let provinceId in window.map.provinces) {
    const province = window.map.provinces[provinceId];
    if (province.nation_id === nation_id) {
      provinceCount++;
    }
  }

  // Compute income and manpower from settlements and provinces
  const incomeFromSettlements = 10 * loyalSettlementCount;
  const manpowerFromSettlements = 100 * loyalSettlementCount;

  const incomeFromProvinces = 1 * provinceCount;
  const manpowerFromProvinces = 5 * provinceCount;

  // Compute expenses from armies
  let armyExpenses = 0;
  for (let armyId in window.map.armies) {
    const army = window.map.armies[armyId];
    if (army.nation_id === nation_id && army.allegiance === 'nation') {
      armyExpenses += army.size * 5; // Assuming 5 per unit
    }
  }

  // Update the HTML elements with the computed values
  document.getElementById('lp-nation-settlement-money-income').innerText = incomeFromSettlements;
  document.getElementById('lp-nation-province-money-income').innerText = incomeFromProvinces;
  document.getElementById('lp-nation-money-expenses').innerText = armyExpenses;

  document.getElementById('lp-nation-settlement-manpower-income').innerText = manpowerFromSettlements;
  document.getElementById('lp-nation-province-manpower-income').innerText = manpowerFromProvinces;
}

btnChangeNationName.addEventListener('click', async function () {
  if (!isEditingNationName) {
    // Switch to edit mode
    isEditingNationName = true;

    // Replace the <h3> with an <input>
    const nationNameElement = document.getElementById('lp-nation-name');
    const nationName = nationNameElement.textContent;

    const input = document.createElement('input');
    input.type = 'text';
    input.id = 'lp-nation-name-input';
    input.value = nationName;
    input.style.fontSize = '24px'; // Match the styling
    input.style.textAlign = 'center';
    input.style.paddingBottom = '10px';
    input.style.marginBottom = '10px';
    input.style.width = '70%';

    nationNameElement.parentNode.replaceChild(input, nationNameElement);

    // Change the icon to a check icon
    const iconImg = btnChangeNationName.querySelector('img');
    iconImg.src = 'check_icon.png'; // Ensure this image exists

  } else {
    // Save the changes
    isEditingNationName = false;

    // Get the new nation name
    const input = document.getElementById('lp-nation-name-input');
    const newNationName = input.value.trim();

    // Validate the new name
    if (newNationName.length === 0) {
      alert('Nation name cannot be empty.');
      return;
    }

    const { data: { session } } = await supabase.auth.getSession();
		const accessToken = session.access_token;

    // Send API request to update the nation name
    fetch('/api/update_nation_name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        nation_id: currentNationId,
        nation_name: newNationName,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Replace the input with the updated nation name
          const h3 = document.createElement('h3');
          h3.id = 'lp-nation-name';
          h3.textContent = newNationName;
          h3.style.textAlign = 'center';
          h3.style.fontSize = '24px';
          h3.style.paddingBottom = '10px';
          h3.style.marginBottom = '10px';

          input.parentNode.replaceChild(h3, input);

          // Change the icon back to the pencil icon
          const iconImg = btnChangeNationName.querySelector('img');
          iconImg.src = 'pencil_icon.png';

          window.map.loadProvinces();

        } else {
          // Handle error
          alert('Error updating nation name: ' + data.error);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('An unexpected error occurred.');
      });
  }
});

